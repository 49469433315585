import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Open Dialog ")])]}}]),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("User Profile")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"First Name","dense":""}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"Middle Name","dense":"","hint":"example of helper text only on focus"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"Last Name","dense":"","persistent-hint":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Email","dense":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"dense":"","label":"Password","type":"password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{attrs:{"items":['0-17', '18-29', '30-54', '54+'],"label":"Age","dense":""}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VAutocomplete,{attrs:{"items":['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump'],"label":"Interests","dense":"","multiple":""}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }